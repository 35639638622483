const getEnvironment = require('@zola-helpers/server/dist/es/environment').default;
const createEnvironmentFlags = require('@zola-helpers/server/dist/es/featureFlags').default;

const flags = {
  // PE-1645 - Best of Zola 2025
  bestOfZola2025: createEnvironmentFlags(true, true, true),
};

const get = (flag) => {
  if (flags[flag]) {
    return Boolean(flags[flag][getEnvironment()]);
  }
  return false;
};

module.exports = {
  get,
};
