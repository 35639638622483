import { createEnvironmentFlags, getFlag } from '@zola-helpers/client/dist/es/featureFlags';
import getEnvironment from '@zola-helpers/client/dist/es/util/environment';

import { parseDateUtc } from '~/util/dateUtils';

/** Approximate time we enabled capturing in-platform reviews in favor of Bazaarvoice capturing reviews */
const inPlatformReviewsEnabledEpoch = (): Date | null => {
  switch (getEnvironment()) {
    case 'development':
      return parseDateUtc('2019-10-04 19:48:55');
    case 'staging': {
      // first review request in staging was on or about: 2019-10-17 18:08:23.809537
      return parseDateUtc('2019-10-17 18:00:00');
    }
    case 'production':
      return parseDateUtc('2019-10-30 13:00:00');
    default:
      return null;
  }
};

export const FLAGS = {
  // Turn on a debugger on the credit packages page to toggle recommendations & promotions
  debugCreditPackageRecommendations: createEnvironmentFlags(false, false, false),

  /** Prevent vendor navigation from navigating so the tracking can be inspected easily */
  debugVendorNavigationClicked: createEnvironmentFlags(false, false, false),

  // INSPO-5360 Convert upload forms to UploadCare
  // This project was put on hold, so set the feature flags back to their production
  // versions to make sure we are consistent across environments
  uploadcareMigration: createEnvironmentFlags(false, false, false),

  // INSPO-6680 Cakes & Desserts Onboarding V2
  enableBakersOnboarding: createEnvironmentFlags(false, false, false),

  // INSPO-6791 Beauticians Onboarding V2
  enableBeauticiansOnboarding: createEnvironmentFlags(false, false, false),

  // INSPO-8897 Social Proof note on storefront cards test has been put aside for now but will be revisted
  // so we are keeing socialProof.tsx in place behind this flag
  enableSocialProofOnStorefrontCard: createEnvironmentFlags(false, false, false),

  disableRealWeddingsSubmissions: createEnvironmentFlags(true, true, true), // Do not remove - Use to toggle

  // PE-1357
  prePurchasePlanImprovements: createEnvironmentFlags(true, true, true),

  // Vendor Gladly Chat
  enableVendorGladlyChat: createEnvironmentFlags(true, true, true),

  // PE-884 Enable Vendor Announcements
  enableVendorAnnouncements: createEnvironmentFlags(true, true, true),

  // PE-1180 - test removing budget from inquiry flow
  removeBudgetFromInquiryFlow: createEnvironmentFlags(false, false, false),

  // PE-1356 - test only removing vendor budget from inquiry flow
  removeVendorBudgetFromInquiryFlow: createEnvironmentFlags(true, true, true),

  // PE-?? - Best of Zola 2025
  bestOfZola2025: createEnvironmentFlags(true, true, true),
};

export type FeatureFlag = keyof typeof FLAGS;

const get = (flag: FeatureFlag): boolean => getFlag(FLAGS, flag);

const featureFlags = {
  get,
  inPlatformReviewsEnabledEpoch,
};

export default featureFlags;
